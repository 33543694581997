import React from 'react';
import dollar from '../../images/dollar.svg';
import './index.scss';

const BorrowerStatistics = () => (
  <div className="borrower-statistics">
    <img alt="dollar sign" src={dollar} width={40} height={40} />
    <p className="borrower-statistics__stat">$90 Billion +</p>
    <p className="borrower-statistics__label">Borrowed</p>
  </div>
);

export default BorrowerStatistics;

