import React from 'react';
import HelpCallout from '../HelpCallout';
import lcIcon from '../../images/lc-icon-only.svg';
import './index.scss';

const AboutLC = () => {
  const lcHistory = (
    <div className="AboutLC-history">
      <img src={lcIcon} className="AboutLC-icon" alt="icon" />
      <div className="AboutLC-title u-title1Text u-colorWhite">Borrow smarter with LendingClub</div>
      <div className="AboutLC-text u-bodyText u-colorWhite">
        Since 2007, LendingClub has helped more than 5 million members get over $90 billion in personal loans so they
        can save money, pay down debt, and start their journey toward being debt-free.
      </div>
    </div>
  );

  return (
    <div className="AboutLC u-bgColorDenim500 u-width100Percent">
      <div className="AboutLC-content">
        {lcHistory}
        <div className="AboutLC-divider" />
        <div className="AboutLC-helper">
          <HelpCallout theme="dark" />
        </div>
      </div>
    </div>
  );
};

export default AboutLC;

