import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from '@shared_modules/local-propulsion/components';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';
import starRating from '../../images/starRating.svg';
import { MEMBERS_REVIEW_URL } from '../../constants/userResourceUrls';
import './index.scss';

const MemberReview = ({ classes }) => {
  const handleReadMoreReviews = e => {
    heapTrackEvent('PL DM - click read more reviews', 'cta');
    window.open(MEMBERS_REVIEW_URL, '_blank');
  };

  return (
    <div className={classnames('MemberReview', classes)}>
      <div className="MemberReview-imgWrapper">
        <img className="MemberReview-img" src={starRating} alt="" aria-hidden />
      </div>
      <div className="MemberReview-quoteSection">
        <div className="MemberReview-quote u-colorDenim500">
          I like LendingClub because I’ve used them in the past. They have my information on file. I seem to get the
          best rates and best terms. It’s a quick loan. Straight to my account. Done in a few days.
        </div>
        <div className="MemberReview-memberName">
          - Tamra B.<sup>3</sup>
        </div>
        <div className="MemberReview-btn">
          <Button
            fullWidth
            color="red"
            variant="secondary"
            name="MemberReview-btn"
            rel="noopener noreferrer"
            onClick={handleReadMoreReviews}
          >
            Read More Reviews
          </Button>
        </div>
      </div>
    </div>
  );
};

MemberReview.propTypes = {
  classes: PropTypes.string,
};

export default MemberReview;

