import React from 'react';
import star from '../../images/star-full.svg';
import partialStar from '../../images/star-partial.svg';
import bazaarVoice from '../../images/bazaar-voice.png';
import './index.scss';

const ReviewStatistics = () => (
  <div className="review-statistics">
    <img alt="member" src={bazaarVoice} width={81} height={30} />
    <p>
      {Array.from({ length: 4 }, (_, index) => (
        <img key={index} alt="full star" src={star} width={14} height={15} />
      ))}
      <img alt="partial star" src={partialStar} width={14} height={15} />
    </p>
    <p className="review-statistics__label">68K+ Reviews</p>
  </div>
);

export default ReviewStatistics;

