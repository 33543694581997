import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './index.scss';

import BorrowedStatistics from '../BorrowedStatistics';
import MemberStatistics from '../MemberStatistics';
import ReviewStatistics from '../ReviewStatistics';

const ReviewWidget = ({ classes }) => {
  return (
    <div className={classnames('ReviewWidget', classes)}>
      <BorrowedStatistics />
      <div className="divider" />
      <MemberStatistics />
      <div className="divider" />
      <ReviewStatistics />
    </div>
  );
};

ReviewWidget.propTypes = {
  classes: PropTypes.string,
};

export default ReviewWidget;

