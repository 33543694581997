import React from 'react';
import member from '../../images/member.svg';
import './index.scss';

const MemberStatistics = () => (
  <div className="member-statistics">
    <img alt="member" src={member} width={40} height={40} />
    <p className="member-statistics__stat">5 Million +</p>
    <p className="member-statistics__label">Members</p>
  </div>
);

export default MemberStatistics;

