import React from 'react';
import Section from '../Section';
import MemberReview from '../MemberReview';
import ReviewWidget from '../ReviewWidget';
import smilingLady from '../../images/smiling-lady.png';

const MemberEndorsement = () => (
  <Section classes="Layout-sectionPadding">
    <div className="Layout-title u-textCenter u-title1Text u-colorDenim500 u-textAlignCenter">What Our Members Say</div>
    <div className="Layout-memberReviewSection">
      <MemberReview />
      <ReviewWidget />
    </div>
    <div className="Layout-smilingLady">
      <img alt="smiling lady" src={smilingLady} width={335} height={347} />
    </div>
  </Section>
);

export default MemberEndorsement;

