const DM_PAGE_CONTENT_MAP = {
  HEADLINE: {
    control: 'Lock in a competitive fixed rate today and start paying down debt fast',
    A: 'Reducing your high-interest debt is our top priority',
    B: 'We’re pleased to present this offer',
    C: 'You deserve this offer',
  },
  BODY: {
    control:
      'Join over 5 million members who have used LendingClub to pay down debt, refinance credit cards, build savings, and reach their financial goals.',
    A: 'Our team is always looking to help you find the best loan offers and interest rates.',
    B: 'A loan could help you pay down balances quickly.',
    C: "Since you've been prescreened you could view your offer in seconds.",
  },
  SUBHEAD: {
    control: "Checking your rate won't affect your credit score",
    A: "We've got you covered at LendingClub",
    B: "We're offering loans with no prepayment penalties",
    C: "Now's an excellent time to check your rate",
  },
  CTA_TEXT: {
    control: 'Check Your Rate',
    A: 'View Your Options',
    B: 'View Your Opportunity',
    C: 'View Your Offer',
  },
};

export const DM_PAGE_CONTENT_DEFAULT = {
  headline: DM_PAGE_CONTENT_MAP.HEADLINE.control,
  body: DM_PAGE_CONTENT_MAP.BODY.control,
  ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.control,
  subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.control,
  hasSup: true,
};

export const DM_PAGE_CONTENT_VARIANTS = {
  A: DM_PAGE_CONTENT_DEFAULT,
  B: {
    ...DM_PAGE_CONTENT_DEFAULT,
    body: DM_PAGE_CONTENT_MAP.BODY.A,
    isLabelVariant: true,
    isCtaVariant: true,
    ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.A,
    subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.A,
    hasSup: false,
  },
  C: {
    ...DM_PAGE_CONTENT_DEFAULT,
    body: DM_PAGE_CONTENT_MAP.BODY.B,
    ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.B,
    isCoverImgVariant: true,
    isCtaVariant: true,
    subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.B,
    hasSup: false,
  },
  D: {
    ...DM_PAGE_CONTENT_DEFAULT,
    body: DM_PAGE_CONTENT_MAP.BODY.C,
    isCoverImgVariant: true,
    isLabelVariant: true,
    ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.C,
    subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.C,
    hasSup: false,
  },
  E: {
    ...DM_PAGE_CONTENT_DEFAULT,
    headline: DM_PAGE_CONTENT_MAP.HEADLINE.A,
    isLabelVariant: true,
    isCtaVariant: true,
    ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.B,
    subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.C,
    hasSup: false,
  },
  F: {
    ...DM_PAGE_CONTENT_DEFAULT,
    headline: DM_PAGE_CONTENT_MAP.HEADLINE.A,
    body: DM_PAGE_CONTENT_MAP.BODY.A,
    ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.C,
    subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.B,
    hasSup: false,
  },
  G: {
    ...DM_PAGE_CONTENT_DEFAULT,
    headline: DM_PAGE_CONTENT_MAP.HEADLINE.A,
    body: DM_PAGE_CONTENT_MAP.BODY.B,
    isCoverImgVariant: true,
    isLabelVariant: true,
    subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.A,
    hasSup: false,
  },
  H: {
    ...DM_PAGE_CONTENT_DEFAULT,
    headline: DM_PAGE_CONTENT_MAP.HEADLINE.A,
    body: DM_PAGE_CONTENT_MAP.BODY.C,
    isCoverImgVariant: true,
    isCtaVariant: true,
    ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.A,
  },
  I: {
    ...DM_PAGE_CONTENT_DEFAULT,
    headline: DM_PAGE_CONTENT_MAP.HEADLINE.B,
    isCoverImgVariant: true,
    isCtaVariant: true,
    ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.C,
    subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.A,
    hasSup: false,
  },
  J: {
    ...DM_PAGE_CONTENT_DEFAULT,
    headline: DM_PAGE_CONTENT_MAP.HEADLINE.B,
    body: DM_PAGE_CONTENT_MAP.BODY.A,
    isCoverImgVariant: true,
    isLabelVariant: true,
    ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.B,
  },
  K: {
    ...DM_PAGE_CONTENT_DEFAULT,
    headline: DM_PAGE_CONTENT_MAP.HEADLINE.B,
    body: DM_PAGE_CONTENT_MAP.BODY.B,
    ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.A,
    subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.C,
    hasSup: false,
  },
  L: {
    ...DM_PAGE_CONTENT_DEFAULT,
    headline: DM_PAGE_CONTENT_MAP.HEADLINE.B,
    body: DM_PAGE_CONTENT_MAP.BODY.C,
    isLabelVariant: true,
    isCtaVariant: true,
    subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.B,
    hasSup: false,
  },
  M: {
    ...DM_PAGE_CONTENT_DEFAULT,
    headline: DM_PAGE_CONTENT_MAP.HEADLINE.C,
    isCoverImgVariant: true,
    isLabelVariant: true,
    ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.A,
    subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.B,
    hasSup: false,
  },
  N: {
    ...DM_PAGE_CONTENT_DEFAULT,
    headline: DM_PAGE_CONTENT_MAP.HEADLINE.C,
    body: DM_PAGE_CONTENT_MAP.BODY.A,
    isCoverImgVariant: true,
    isCtaVariant: true,
    subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.C,
    hasSup: false,
  },
  O: {
    ...DM_PAGE_CONTENT_DEFAULT,
    headline: DM_PAGE_CONTENT_MAP.HEADLINE.C,
    body: DM_PAGE_CONTENT_MAP.BODY.B,
    isLabelVariant: true,
    isCtaVariant: true,
    ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.C,
  },
  P: {
    ...DM_PAGE_CONTENT_DEFAULT,
    headline: DM_PAGE_CONTENT_MAP.HEADLINE.C,
    body: DM_PAGE_CONTENT_MAP.BODY.C,
    ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.B,
    subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.A,
    hasSup: false,
  },
};

